.skills {
  text-align: center;
  margin-bottom: 3rem;
}

.skills__cloud {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 900px;
  margin: 0 auto;
  gap: 1.5rem;
}

.skills__group {
  text-align: center;
}

.skills__group h3 {
  font-size: 1rem;
  color: var(--clr-primary);
  margin-bottom: 0.5rem;
}

.skills__tags {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.5rem;
}

.skills__tag {
  background: var(--clr-bg-alt);
  color: var(--clr-fg);
  padding: 0.4rem 0.8rem;
  border-radius: 5px;
  font-size: 0.9rem;
  font-weight: 500;
  box-shadow: var(--shadow);
  transition: transform 0.2s ease-in-out;
}

.skills__tag:hover {
  transform: translateY(-2px);
}
