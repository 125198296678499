.project {
  background: var(--clr-bg-alt); 
  padding: 2em;
  margin: 0 auto;
  text-align: center;
  box-shadow: var(--shadow);
  transition: transform 0.2s linear;
}

.project:hover {
  transform: translateY(-7px);
}

.project__description {
  margin-top: 1em;
}

.project__stack {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 1.2em 0;
}

.project__stack-item {
  margin: 0.5em;
  font-weight: 500;
  font-size: 0.8rem;
  color: var(--clr-fg-alt);
}

.project__image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 1em;
  object-fit: cover;
  filter: grayscale(20%) contrast(90%) brightness(95%);
  transition: filter 0.3s ease-in-out, transform 0.2s ease-in-out;
}

.project__image:hover {
  filter: grayscale(0%) contrast(100%) brightness(100%);
  transform: scale(1.02);
}


.project .link--icon {
  margin-left: 0.5em;
}

